import axios from 'axios';
import store from '../../store/index'
import {backendApi} from "../backend-api-sr"

export const user = (() =>{
    const checkAuth = ( async (to, from, resolve, reject) => {
        axios.get(`${process.env.VUE_APP_URL}/api/cek_token`, { 
            headers: { Authorization: `Bearer ${store.getters.isTokenUser}` } 
        }).then(response => {
            // TODO nanti diperbaiki lagi
            // Token is valid, so continue
            return resolve();
        }).catch(error => {
            // There was an error so redirect
            router.push({ path: "/" });
        })
    })

    return {checkAuth};
})()
<template>
    <v-container fluid class="mb-12">
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :to="item.href" class="text-subtitle-2 crumb-item" :disabled="item.disabled"
                                exact>
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color"
                        :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout"
                        :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                            <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                            <v-layout column>
                                <div>
                                    <strong>{{ snackbar.title }}</strong>
                                </div>
                                <div>{{ snackbar.text }}</div>
                            </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                            <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                </div>
            </v-col>
            <!-- TODO dibetulin setelah coba input data -->
            <v-col cols="12">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col class="col-6" sm="6" md="2">
                                <p class="blue-lcd mb-0 pa-0">Region </p>
                                <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="region"
                                    :items="regions" default="" item-value="region" item-text="region" label="Region"
                                      clearable @change="(event) => getSalesman(event)" :disabled="isRegionDisabled" :auto-select-first="isRegionDisabled">
                                </v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <p class="blue-lcd mb-0 pa-0">Sales </p>
                                <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="sales"
                                    :items="saleses" default="" item-value="salesman_id" item-text="salesman_name" label="Sales"
                                      clearable :disabled="isSalesDisabled" :auto-select-first="isSalesDisabled">
                                </v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <p class="blue-lcd mb-0 pa-0">Type </p>
                                <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="type"
                                    :items="types" default="" item-value="str1" item-text="str2" label="Type" clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <p class="blue-lcd mb-0 pa-0">Category </p>
                                <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="category"
                                    :items="categories" default="" item-value="str1" item-text="str2" label="Category" clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <p class="blue-lcd ma-0 pa-0">Application </p>
                                <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="appl_search"
                                    :items="applications" default="" item-value="str1" item-text="str2" label="Application"
                                    :disabled="isView">
                                </v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <p class="blue-lcd mb-0 pa-0">Association </p>
                                <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="association"
                                    :items="associations" default="" item-value="str1" item-text="str2" label="Association" clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <p class="blue-lcd mb-0 pa-0">Roll Former </p>
                                <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="rollFormer"
                                    :items="rollFormers" default="" item-value="cust_id" item-text="cust_name" label="Roll Former" clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                            <p class="blue-lcd mb-0 pa-0">Project Province </p>
                            <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="province"
                                :items="provinces" default="" item-value="province" item-text="province" label="Province"
                                @change="(event) => getCity(event)" clearable>
                            </v-autocomplete>
                        </v-col>
                        <v-col class="col-6" sm="6" md="2">
                            <p class="blue-lcd mb-0 pa-0">Project City </p>
                            <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="city"
                                :items="cities" default="" item-value="city" item-text="city" label="City" clearable>
                            </v-autocomplete>
                        </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <p class="blue-lcd mb-0 pa-0">Partner </p>
                                <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="partnerDialog"
                                    :items="partnersDialog" default="" item-value="partner_id" item-text="partner_desc" label="Partner" clearable>
                                </v-autocomplete>
                            </v-col>
                            
                            <v-col class="col-6" sm="6" md="2">
                                <p class="blue-lcd mb-0 pa-0">Start Period </p>
                                <v-menu ref="modal" 
                                v-model="modal" 
                                :close-on-content-click="false" 
                                transition="scale-transition" 
                                offset-y 
                                max-width="290px" 
                                min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        dense
                                        clearable
                                        solo 
                                        v-model="period" 
                                        label="Period" 
                                        persistent-hint 
                                        append-icon="mdi-calendar" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        class="ma-0 border-12" 
                                        hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker 
                                        v-model="period" 
                                        no-title @input="modal = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <p class="blue-lcd mb-0 pa-0">To Period </p>
                                <v-menu ref="modal_to_period" 
                                v-model="modal_to_period" 
                                :close-on-content-click="false" 
                                transition="scale-transition" 
                                offset-y 
                                max-width="290px" 
                                min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        dense
                                        clearable
                                        solo 
                                        v-model="period_to" 
                                        label="To Period" 
                                        persistent-hint 
                                        append-icon="mdi-calendar" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        class="ma-0 border-12" 
                                        hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker 
                                        v-model="period_to" 
                                        no-title @input="modal_to_period = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>                            
                        </v-row>
                        <v-row align="center" justify="center" class="mt-3"> 
                            <v-col class="col-6" sm="6" md="2">
                                <v-btn class="border-12" style="padding: 19px;" color="info" elevation="2" small block @click="getHeader()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-btn small block color="#005c37" class="py-5 mr-3 text-white border-12">
                                    <v-icon>mdi-file-excel</v-icon>
                                    <download-excel class="text" :fetch="exportExcel" :fields="headersColumn"
                                        :before-generate="startDownload" :before-finish="finishDownload">
                                    </download-excel>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <!-- dialog detail -->
            <v-dialog v-model="dialogCreate" fullscreen hide-overlay transition="dialog-bottom-transition">
                <!-- <v-card> -->
                    <v-toolbar dark
                        color="primary">
                        <v-btn icon
                            dark @click=cancel()>
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{dialogTitle}} <span class="text-darken-3 m-0"> {{ projectId }}</span> </v-toolbar-title>
                    </v-toolbar>
                    <v-row>
                        <v-card outlined class="mb-4 mt-4 ml-2 mr-2" >
                            <!-- <v-card-title>
                                <v-col>
                                    Header
                                </v-col> -->
                                <!-- <v-col cols="6">
                                    Order No : <span class="cyan-text text-darken-3 m-0"> {{ orderNo }}</span>
                                </v-col>     -->
                            <!-- </v-card-title> -->
                            <v-container fluid>
                                <v-row>
                                    <v-col  cols="12" xs="12" sm="12" md="12">
                                        <p >Header</p>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="col-6" sm="6" md="2">
                                        <p class="blue-lcd ma-0 pa-0">Region <strong style="color:red;">*</strong><span></span></p>
                                        <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="regionDialog"
                                            :items="regionsDialog" default="" item-value="region" item-text="region" label="Region"
                                            @change="(event) => getSalesman(event)" :disabled="isRegionDialogDisabled || isRegionDialogEditDisabled" :auto-select-first="isRegionDialogDisabled">
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col class="col-6" sm="6" md="2">
                                        <p class="blue-lcd ma-0 pa-0">Salesman <strong style="color:red;">*</strong><span></span></p>
                                        <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="salesDialog"
                                            :items="salesesDialog" default="" item-value="salesman_id" item-text="salesman_name" label="Sales"
                                            :disabled="isSalesDialogDisabled || isSalesDialogEditDisabled" :auto-select-first="isSalesDialogDisabled">
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col class="col-6" sm="6" md="2">
                                        <p class="blue-lcd ma-0 pa-0">Type <strong style="color:red;">*</strong><span></span></p>
                                        <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="typeDialog"
                                            :items="typesDialog" default="" item-value="str1" item-text="str2" label="Type" :disabled="isView">
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col class="col-6" sm="6" md="2">
                                        <p class="blue-lcd ma-0 pa-0">Project Name <strong style="color:red;">*</strong><span></span></p>
                                        <v-text-field dense solo class="ma-0 pa-0 border-12" v-model="projectNameDialog" 
                                            label="Project Name" single-line hide-details :disabled="isView">
                                        </v-text-field>
                                    </v-col>
                                    <v-col class="col-6" sm="6" md="2">
                                        <p class="blue-lcd ma-0 pa-0">Category <strong style="color:red;">*</strong><span></span></p>
                                        <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="categoryDialog"
                                            :items="categoriesDialog" default="" item-value="str1" item-text="str2" label="Category"
                                            :disabled="isView">
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col class="col-6" sm="6" md="2">
                                        <p class="blue-lcd ma-0 pa-0">Application <strong style="color:red;">*</strong><span></span></p>
                                        <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="application"
                                            :items="applications" default="" item-value="str1" item-text="str2" label="Application"
                                            :disabled="isView">
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col class="col-6" sm="6" md="2">
                                        <p class="blue-lcd ma-0 pa-0">Roll Former <strong style="color:red;">*</strong><span></span></p>
                                        <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="rollFormerDialog"
                                            :items="rollFormersDialog" default="" item-value="cust_id" item-text="cust_name" label="Roll Former"
                                            :disabled="isView">
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col class="col-6" sm="6" md="2">
                                        <p class="blue-lcd ma-0 pa-0">Project Address </p>
                                        <v-text-field dense solo class="ma-0 pa-0 border-12" v-model="projectAddressDialog" 
                                            label="Project Address" single-line hide-details
                                            :disabled="isView">
                                        </v-text-field>
                                    </v-col>
                                    <v-col class="col-6" sm="6" md="2">
                                        <p class="blue-lcd ma-0 pa-0">Project Province </p>
                                        <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="provinceDialog"
                                            :items="provincesDialog" default="" item-value="province" item-text="province" label="Province"
                                            :disabled="isView"
                                            @change="(event) => getCity(event)">
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col class="col-6" sm="6" md="2">
                                        <p class="blue-lcd ma-0 pa-0">Project City </p>
                                        <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="cityDialog"
                                            :items="citiesDialog" default="" item-value="city" item-text="city" label="City"
                                            :disabled="isView">
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col class="col-6" sm="6" md="2">
                                        <p class="blue-lcd ma-0 pa-0">Partner <strong style="color:red;">*</strong><span></span></p>
                                        <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="partnerDialog"
                                            :items="partnersDialog" default="" item-value="partner_id" item-text="partner_desc" label="Partner"
                                            :disabled="isView" @change="(event) => getPartnerDetail(event)">
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col class="col-6" sm="6" md="2">
                                        <p class="blue-lcd ma-0 pa-0">PIC Project Phone </p>
                                        <v-text-field dense solo class="ma-0 pa-0 border-12" v-model="projectPhoneDialog" 
                                            label="PIC Project Phone" single-line hide-details
                                            :disabled="isView">
                                        </v-text-field>
                                    </v-col>
                                    <v-col class="col-6" sm="6" md="2">
                                        <p class="blue-lcd ma-0 pa-0">Association <strong style="color:red;">*</strong><span></span></p>
                                        <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="associationDialog"
                                            :items="associationsDialog" default="" item-value="str1" item-text="str2" label="Association"
                                            :disabled="isView">
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col class="col-6" sm="6" md="2">
                                        <p class="blue-lcd ma-0 pa-0">Project Size </p>
                                        <v-text-field dense solo class="ma-0 pa-0 border-12" v-model="projectSize" 
                                            label="Project Size" single-line hide-details 
                                            :disabled="isView" suffix="m2">
                                        </v-text-field>
                                    </v-col>
                                    <v-col class="col-6" sm="6" md="2">
                                        <p class="blue-lcd ma-0 pa-0">BMT </p>
                                        <v-text-field dense solo class="ma-0 pa-0 border-12" v-model="bmt" 
                                            label="BMT" single-line hide-details 
                                            :disabled="isView">
                                        </v-text-field>
                                    </v-col>
                                    <v-col class="col-6" sm="6" md="2">
                                        <p class="blue-lcd ma-0 pa-0">Start Project <strong style="color:red;">*</strong><span></span></p>
                                        <v-menu ref="startProjectModal" 
                                            v-model="startProjectModal" 
                                            :close-on-content-click="false" 
                                            transition="scale-transition" 
                                            offset-y 
                                            max-width="290px" 
                                            min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    dense
                                                    :disabled="isStartProjectEditDialogDisabled"
                                                    clearable
                                                    solo 
                                                    v-model="startDateDialog" 
                                                    label="Start Date" 
                                                    persistent-hint 
                                                    append-icon="mdi-calendar" 
                                                    v-bind="attrs" 
                                                    v-on="on" 
                                                    class="ma-0 border-12" 
                                                    hide-details=true>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker 
                                                v-model="startDateDialog" 
                                                no-title @input="startProjectModal = false">
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col class="col-6" sm="6" md="2">
                                        <p class="blue-lcd ma-0 pa-0">End Project </p>
                                        <v-menu ref="endProjectModal" 
                                        v-model="endProjectModal" 
                                        :close-on-content-click="false" 
                                        transition="scale-transition" 
                                        offset-y 
                                        max-width="290px" 
                                        min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                dense
                                                clearable
                                                solo 
                                                v-model="endDateDialog" 
                                                label="End Date" 
                                                persistent-hint 
                                                append-icon="mdi-calendar" 
                                                v-bind="attrs" 
                                                v-on="on" 
                                                class="ma-0 border-12" 
                                                hide-details=true
                                                :disabled="isView">
                                                </v-text-field>
                                            </template>
                                            <v-date-picker 
                                                v-model="endDateDialog" 
                                                no-title @input="endProjectModal = false">
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <v-row align="center" justify="center">
                                    <v-col class="col-6" sm="6" md="2">
                                        <v-btn v-if="isView === false" class="mt-5 mr-4 border-12" style="padding: 19px;" block small elevation="2" @click="clear()">New Project</v-btn>
                                    </v-col>
                                    <v-col class="col-6" sm="6" md="2">
                                        <v-btn v-if="isNew === true && isUpdate === false" :disabled="disableSaveHeader"
                                            class="mt-5 mr-4 border-12" style="padding: 19px;" block small color="primary" elevation="2" @click="saveHeader()">Save Project</v-btn>
                                        <v-btn v-if="isNew === false && isUpdate === true"
                                            class="mt-5 mr-4 border-12" style="padding: 19px;" block small color="primary" elevation="2" @click="updateHeader()">Update Project</v-btn>
                                    </v-col>
                                    <v-col class="col-6" sm="6" md="2" v-if="isNew === false && isUpdate === true">
                                        <v-btn v-if="isNew === false && isUpdate === true"
                                            class="mt-5 mr-4 border-12" style="padding: 19px;" block small color="primary" elevation="2" @click="closeHeader()">Close Project</v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-row>
                    
                    <!-- <v-row v-if="projectId"> -->
                    <v-row  v-if="isDetailDialogDisabled === false">
                        <v-card outlined class="mb-4 ml-2 mr-2" :disabled="isDetailDialogDisabled">
                            <v-container fluid>
                                <v-row>
                                    <v-col  cols="12" xs="12" sm="12" md="12">
                                        <p class="mb-0 pa-0">Detail</p>
                                    </v-col>
                                </v-row>
                                
                                <v-row>
                                    <v-col class="col-6" sm="6" md="2">
                                        <p class="blue-lcd mb-0 pa-0">Survey Date <strong style="color:red;">*</strong><span></span></p>
                                        <v-menu ref="surveyDateModal" 
                                            v-model="surveyDateModal" 
                                            :close-on-content-click="false" 
                                            transition="scale-transition" 
                                            offset-y 
                                            max-width="290px" 
                                            min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                dense
                                                clearable
                                                solo 
                                                v-model="surveyDateDialog" 
                                                label="Survey Date" 
                                                persistent-hint 
                                                append-icon="mdi-calendar" 
                                                v-bind="attrs" 
                                                v-on="on" 
                                                class="ma-0 border-12" 
                                                hide-details=true>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker 
                                                v-model="surveyDateDialog" 
                                                no-title @input="surveyDateModal = false">
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col class="col-6" sm="6" md="2">
                                        <p class="blue-lcd mb-0 pa-0">Stage <strong style="color:red;">*</strong><span></span></p>
                                        <v-autocomplete dense solo class="ma-0 pa-0 border-12" hide-details=true v-model="stageDialog"
                                            :items="stagesDialog" default="" item-value="str1" item-text="str2" label="Stage"
                                            @change="(event) => checkRequired(event)">
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col class="col-6" sm="6" md="2">
                                        <p class="blue-lcd  mb-0 pa-0">Remark </p>
                                        <v-text-field dense solo class="ma-0 pa-0 border-12" v-model="remarkDialog" 
                                            label="Remark" single-line hide-details
                                            :disabled="isView">
                                        </v-text-field>
                                    </v-col>
                                    <v-col class="col-6" sm="6" md="2">
                                        <p class="blue-lcd mb-0 pa-0">Upload File 
                                            <strong v-if="isUploadFileRequired"  style="color:red;">*</strong><span></span>
                                        </p>
                                        <v-file-input
                                            solo
                                            v-model="files"
                                            label="Picture file"
                                            small-chips
                                            counter
                                            multiple
                                            show-size
                                            append-icon="mdi-image"
                                            class="border-12"
                                            accept="image/*"
                                            @change="handleFileUpload"
                                        ></v-file-input>
                                    </v-col>
                                    <v-col class="col-6" sm="6" md="2">
                                        <v-btn class="mt-5 mr-4 border-12" style="padding: 19px;" block small elevation="2" @click="clearDetail()">New Stage</v-btn>
                                    </v-col>
                                    <v-col class="col-6" sm="6" md="2">
                                        <v-btn class="mt-5 mr-4 border-12" style="padding: 19px;" block small color="primary" elevation="2" @click="saveDetail()">Save Stage</v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-row>
                    <v-row>
                        <v-card outlined class="mb-8 ml-2 mr-2">
                            <v-col cols="12">
                                <v-data-table
                                    :headers="headerDetailDialog"
                                    :items="detailData"
                                    :loading="$store.state.overlay"
                                    :item-class="tr_datatable"
                                    :items-per-page="20"
                                    fixed-header
                                    height="300"
                                    :divider="true"
                                    :light="true"
                                    :search="searchDetailItem"
                                >
                                    <template v-slot:top>
                                        <v-toolbar flat color="white">
                                            <div class="d-flex w-100">
                                                <p class="cyan-text text-darken-3 m-0">Detail</p>
                                                <v-spacer></v-spacer>
                                                <v-text-field solo style="max-width: 300px;" class="border-12" v-model="searchDetailItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                            </div>
                                        </v-toolbar>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <td>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }" v-if="item.total_file > 0">
                                                    <v-icon small class="mr-2" @click="showImageDialog(item)" v-on="on">
                                                        mdi-image
                                                    </v-icon>
                                                </template>
                                                <span>View Images</span>
                                            </v-tooltip>
                                        </td>
                                        <td v-if="isAllowCrud">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon small class="mr-2" @click="deleteDetailDialog(item)" v-on="on">
                                                        mdi-trash-can-outline
                                                    </v-icon>
                                                </template>
                                                <span>Delete</span>
                                            </v-tooltip>
                                        </td>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-card>
                    </v-row>

                <!-- </v-card> -->
            </v-dialog>
            <!-- Dialog view images -->
            <v-dialog 
                v-model="dialogAlbum"
                max-width="1000px">
                <v-card>
                    <v-toolbar
                        color="primary text-white"
                    >
                    <span  style="color:white">
                        View Photos
                    </span>
                    </v-toolbar>
                    
                    <v-card-text class="mt-3">
                        <v-carousel
                            progress
                            cycle
                            interval="10000"
                            show-arrows-on-hover
                        >
                            <v-carousel-item
                                v-for="(imageData,i) in imageData"
                                :key="i"
                                :src="imageData.src"
                                lazy-src="https://picsum.photos/id/11/100/60"
                                reverse-transition="fade-transition"
                                transition="fade-transition"
                                contain
                            >
                                <template v-slot:placeholder>
                                    <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                        ></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-carousel-item>
                        </v-carousel>

                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDialogAlbum">Cancel</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <!-- Header table -->
        <v-row class="mb-5">
            <v-col class="col-12">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    :headers="header"
                                    :items="data"
                                    :loading="$store.state.overlay"
                                    :item-class="tr_datatable"
                                    :items-per-page="20"
                                    fixed-header
                                    height="500"
                                    :divider="true"
                                    :light="true"
                                    :search="searchItem"
                                >        
                                    <template v-slot:top>
                                        <v-toolbar flat color="white">
                                            <div class="d-flex w-100">
                                                <p class="cyan-text text-darken-3 m-0 hidden-mobile">List</p>
                                                <v-spacer></v-spacer>
                                                <v-text-field solo style="max-width: 300px;" class="border-12 mr-3" v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                <v-btn v-if="isAllowCrud" class="border-12" style="padding: 19px;" small color="success" elevation="2" @click="addNewProject()"><v-icon>mdi-plus</v-icon> New Project</v-btn>
                                            </div>
                                        </v-toolbar>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <td v-if="isAllowCrud === true  && item.status != 'C'">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon small class="mr-2" @click="editProject(item)" v-on="on">
                                                        mdi-pencil
                                                    </v-icon>
                                                </template>
                                                <span>Edit Project</span>
                                            </v-tooltip>
                                        </td>
                                        <td v-if="isAllowCrud == false">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon small class="mr-2" @click="viewProject(item)" v-on="on">
                                                        mdi-eye-outline
                                                    </v-icon>
                                                </template>
                                                <span>View Project</span>
                                            </v-tooltip>
                                        </td>
                                        <td v-if="isAllowCrud === true && item.status != 'C'">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon small class="mr-2" @click="deleteProject(item)" v-on="on">
                                                        mdi-trash-can-outline
                                                    </v-icon>
                                                </template>
                                                <span>Delete</span>
                                            </v-tooltip>
                                        </td>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {projectTracking} from "../../../backend-api/sr/project/project_tracking"
import {user} from "../../../backend-api/util/user"
import {backendApi} from "../../../backend-api/backend-api-sr"

export default {
    data() {
        return{
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            timeout: 7500,
            search: '',
            breadcumbs: [
                {
                    text: 'SR',
                    disabled: false,
                    href: '/admin/sr',
                },
                {
                    text: 'Project',
                    disabled: true,
                    href: '/admin/sr',
                },
                {
                    text: 'Project Tracking',
                    disabled: true
                }
            ],
            data_var: {
                entity_id : 'SR',
                appl_id : 'WEBSR'
            },
            regionEnv:'',
            salesid:'',
            filePath:'',
            isAllowCrud:false,
            isNew:false,
            isUpdate:false,
            dialogTitle:'',
            // Header
            disableSaveHeader:false,
            region:'',
            regions:[],
            isRegionDisabled:false,
            type:'',
            types:[],
            sales:'',
            saleses:[],
            isSalesDisabled:false,
            association:'',
            associations:[],
            category:'',
            categories:[],
            rollFormer:'',
            rollFormers:[],
            partner:'',
            partners:[],
            province:'',
            provinces:[],
            city:'',
            cities:[],
            modal:false,
            period:'',
            modal_to_period:false,
            period_to:'',
            header:[
                { text: 'Actions',  value: 'actions', sortable: false },
                { text: 'Project No', value: 'project_id'},
                { text: 'Status', value: 'status'},
                { text: 'Region', value: 'region' , align:'left'},
                { text: 'Customer', value: 'cust_name'},
                { text: 'Salesman', value: 'salesman_name' , align:'left'},
                { text: 'Project Type', value: 'project_type_desc'},
                { text: 'Project Name', value: 'project_name'},
                { text: 'Project Category', value: 'project_category_desc' , align:'left'},
                { text: 'Application', value: 'application' , align:'left'},
                { text: 'Project Address', value: 'project_address' , align:'left'},
                { text: 'Project City', value: 'project_city' , align:'left'},
                { text: 'Project Province', value: 'project_province' , align:'left'},
                { text: 'Project Size (m2)', value: 'project_size' , align:'left'},
                { text: 'BMT', value: 'bmt' , align:'left'},
                { text: 'Stage', value: 'stage_desc' , align:'left'},
                { text: 'PIC Phone', value: 'project_pic_phone' , align:'left'},
                { text: 'Start Date', value: 'start_date' , align:'left'},
                { text: 'End Date', value: 'end_date' , align:'left'},
                { text: 'Association', value: 'asosiasi_desc'},
                { text: 'Partner', value: 'partner_desc' , align:'left'},
            ],
            headersColumn: {
                'Project No': 'project_id',
                'Status': 'status',
                'Region': 'region',
                'Customer': 'cust_name',
                'Sales': 'salesman_name',
                'Project Type': 'project_type_desc',
                'Project Name': 'project_name',
                'Project Category': 'project_category_desc',
                'Application': 'application',
                'Project Address': 'project_address',
                'Project City': 'project_city',
                'Project Province': 'project_province',
                'Project Size (m2)': 'project_size',
                'BMT': 'bmt',
                'Stage': 'stage_desc',
                'Project Pic Phone': 'project_pic_phone',
                'Start Date': 'start_date',
                'End Date': 'end_date',
                'Association': 'asosiasi_desc',
                'Partner': 'partner_desc'
            },
            data:[],
            searchItem:'',
            isView:false,
            isDetailDialogDisabled:false,
            // dialog
            dialogCreate:false,
            projectId:'',
            regionDialog:'',
            regionsDialog:[],
            isRegionDialogDisabled:false,
            isRegionDialogEditDisabled:false,
            salesDialog:'',
            salesesDialog:[],
            isSalesDialogDisabled:false,
            isSalesDialogEditDisabled:false,
            typeDialog:'',
            typesDialog:[],
            associationDialog:'',
            associationsDialog:[],
            categoryDialog:'',
            categoriesDialog:[],
            projectNameDialog:'',
            projectAddressDialog:'',
            projectPhoneDialog:'',
            rollFormerDialog:'',
            rollFormersDialog:[],
            provinceDialog:'',
            provincesDialog:[],
            cityDialog:'',
            citiesDialog:[],
            partnerDialog:'',
            partnersDialog:[],
            stageDialog:'',
            stagesDialog:[],
            isStartProjectEditDialogDisabled:false,
            startProjectModal:false,
            startDateDialog:'',
            endProjectModal:false,
            endDateDialog:'',
            surveyDateDialog:'',
            surveyDateModal:false,
            remarkDialog:'',
            isUploadFileRequired:false,
            files: [],
            regionDialogDisabled:false,
            // dialog table
            headerDetailDialog:[
                { text: 'Actions',  value: 'actions', sortable: false },
                { text: 'Survey Date', value: 'survey_date'},
                { text: 'Stage', value: 'str2' , align:'left'},
                { text: 'Remark', value: 'remark' , align:'left'},
            ],
            detailData:[],
            searchDetailItem:'',
            // dialog images
            dialogAlbum:false,
            pictureDatas:[],
            projectSize: '',
            bmt: '',
            application:'',
            applications: [],
            appl_search: ''
        }
    },
    props: {
        isCreate: Boolean,
    },
    async mounted(){},
    computed:{
        imageData() {
            return this.pictureDatas.map(item => ({
                ...item,
                src: process.env.VUE_APP_URL + '/storage' + item.src
            }));
        },
    },
    methods:{
        tr_datatable(item) {
            const rowClass = 'tr_datatable'
            return rowClass;
        },
        cancel(){
            this.dialogCreate = false
            this.clear()
            this.clearDetail()
            this.getHeader()
        },
        async initView(){
            this.$store.dispatch('setOverlay', true)
            this.clearFilter()
            this.clear()
            this.clearDetail()
            this.initAllArr()
            this.cekParam()
            this.closeDialogAlbum()
            await this.getEnvConf()
            await this.getRegion()
            await this.getApplConstant()
            await this.getProvince()
            await this.getCity()
            await this.getRollFormer()
            await this.getPartner()
            this.$store.dispatch('setOverlay', false)    
        },
        initAllArr(){
            this.regions = []
            this.types = []
            this.saleses = []
            this.associations = []
            this.categories = []
            this.rollFormers = []
            this.partners = []
            this.provinces = []
            this.cities = []
            this.data = []
            this.detailData = []
        },
        addNewProject(){
            this.isNew = true
            this.isUpdate = false
            this.dialogCreate = true
            this.isDetailDialogDisabled = true
        },
        closeProject(){
            // TODO
        },
        
        async editProject(event){
            console.log(event);
            this.isNew = false
            this.isUpdate = true
            this.dialogCreate = true
            this.isDetailDialogDisabled = true
            await this.editHeader(event)
            this.isDetailDialogDisabled = false
            // console.log('edit', event)
        },
        async deleteProject(event){
            // TODO tambah dialog kalau perlu confirm
            // console.log(event)
            this.deleteHeader(event)
        },
        async viewProject(event){
            // TODO belum handle blas
            this.isNew = false
            this.isUpdate = false
            this.dialogCreate = true
            this.isDetailDialogDisabled = true
            await this.editHeader(event)
            this.isView = true
            this.isDetailDialogDisabled = true
        },
        cekParam(){
            if(this.isCreate){
                this.breadcumbs = [
                    {
                        text: 'SR',
                        disabled: false,
                        href: '/admin/sr',
                    },
                    {
                        text: 'Project',
                        disabled: true,
                        href: '/admin/sr',
                    },
                    {
                        text: 'Project Tracking',
                        disabled: true
                    }
                ]
                this.isAllowCrud = this.isCreate
            } else {
                this.breadcumbs = [
                    {
                        text: 'SR',
                        disabled: false,
                        href: '/admin/sr',
                    },
                    {
                        text: 'Project',
                        disabled: true,
                        href: '/admin/sr',
                    },
                    {
                        text: 'Project Reporting',
                        disabled: true
                    }
                ]
                this.isAllowCrud = this.isCreate
            }
        },
        checkRequired(event){
            // console.log(event)
            // console.log(this.stagesDialog)
            const item = this.stagesDialog.find((item) => item.str1 === event);
            // console.log(item.int1)
            if(item.int1 === '0'){
                this.isUploadFileRequired = false
            } else {
                this.isUploadFileRequired = true
            }
            console.log('cek required', this.isUploadFileRequired)
        },
        clearFilter(){
            if(!this.isRegionDisabled){
                this.region = ''
            }
            if(!this.isSalesDisabled){
                this.sales = ''
            }
            this.type = ''
            this.association = ''
            this.category = ''
            this.rollFormer = ''
            this.partner = ''
            this.province = ''
            this.city = ''
            this.period = ''
            this.period_to = ''
        },
        clear(event){
            if(!this.isRegionDialogDisabled){
                this.regionDialog = ''
            }
            if(!this.isSalesDialogDisabled){
                this.salesDialog = ''
            }
            this.dialogTitle = ''
            this.isNew = true
            this.isUpdate = false
            this.disableSaveHeader = false
            this.projectId = ''
            this.typeDialog = ''
            this.projectNameDialog = ''
            this.categoryDialog = ''
            this.rollFormerDialog = ''
            this.projectAddressDialog = ''
            this.provinceDialog = ''
            this.cityDialog = ''
            this.partnerDialog = ''
            this.projectPhoneDialog = ''
            this.associationDialog = ''
            this.endDateDialog = ''
            this.detailData=[]
            this.isRegionDialogEditDisabled = false
            this.isSalesDialogEditDisabled = false
            this.isStartProjectEditDialogDisabled = false
            this.isDetailDialogDisabled = false
            this.isView = false
        },
        clearDetail(event){
            this.stageDialog = ''
            this.surveyDateDialog = ''
            this.remarkDialog = ''
            this.files= []
        },
        async getEnvConf(){
            let data = this.data_var;
            var res = await this.$store.dispatch("getVariabelUser", { data })
            if(res.data.data != null){
                for (let i = 0; i < res.data.data.length; i++) {
                    if (res.data.data[i]['var_id'] === 'REGIONSPEC') {
                        if (res.data.data[i]['var_value'] != null) {
                            this.regionEnv = res.data.data[i]['var_value']
                        }
                    }
                    if (res.data.data[i]['var_id'] === 'SALESSPEC') {
                        if (res.data.data[i]['var_value'] != null) {
                            this.salesid = res.data.data[i]['var_value']
                        }
                    }
                }
            }
        },
        async getRegion(event){
            const respData = await projectTracking.fetchRegion(`?region=${this.regionEnv}&salesman_id=${this.salesid}`, null, false, false, false)
            if (respData.status === 200) {
                this.regionsDialog = respData.data.data
                this.regions = respData.data.data
                if(respData.data.data.length == 1){
                    this.region = this.regions[0]['region']
                    // console.log(this.region['region'])
                    this.regionDialog = this.regionsDialog[0]['region']
                    this.isRegionDisabled = true
                    this.isRegionDialogDisabled = true
                    await this.getSalesman(this.region)
                }
                
            }
        },
        async getSalesman(event){
            const respData = await projectTracking.fetchSalesman(`?region=${event}&salesman_id=${this.salesid}`, null, false, false, false)
            if (respData.status === 200) {
                this.salesesDialog = respData.data.data
                this.saleses = respData.data.data
                if(respData.data.data.length == 1){
                    this.isSalesDisabled = true
                    this.isSalesDialogDisabled = true
                    this.sales = this.saleses[0]['salesman_id']
                    this.salesDialog = this.salesesDialog[0]['salesman_id']
                }
            }
        },
        async getApplConstant(event){
            const respData = await projectTracking.fetchApplConstant('', null, false, false, false)
            if (respData.status === 200) {
                let dt = respData.data.data
                this.typesDialog = dt.dataType
                this.types = dt.dataType
                this.associationsDialog = dt.dataAssoc
                this.associations = dt.dataAssoc
                this.categoriesDialog = dt.dataCategory
                this.categories = dt.dataCategory
                this.filePath = dt.dataFilePath[0].str2
                this.stagesDialog = dt.dataStage
                this.applications = dt.dataApplication
            }
        },
        async getProvince(event){
            const respData = await projectTracking.fetchProvince(``, null, false, false, false)
            if (respData.status === 200) {
                this.provincesDialog = respData.data.data
                this.provinces = respData.data.data
            }
        },
        async getCity(event){
            const respData = await projectTracking.fetchCity(`?province=${event}`, null, false, false, false)
            if (respData.status === 200) {
                this.citiesDialog = respData.data.data
                this.cities = respData.data.data
            }
        },
        async getRollFormer(event){
            const respData = await projectTracking.fetchRollFormer(`?province=${this.provinceDialog}`, null, false, false, false)
            if (respData.status === 200) {
                this.rollFormersDialog = respData.data.data
                this.rollFormers = respData.data.data
            }
        },
        async getPartner(event){
            const respData = await projectTracking.fetchPartner(`?province=${this.provinceDialog}`, null, false, false, false)
            if (respData.status === 200) {
                this.partnersDialog = respData.data.data
                this.partners = respData.data.data
            }
        },
        async getHeader(event){
            this.dialogTitle = 'Project :'
            var periodStart = this.period ? new Date(this.period).toISOString().substr(0, 10) : ''
            var periodEnd = this.period_to ? new Date(this.period_to).toISOString().substr(0, 10) : ''
            var reqBody = {
                "region" : this.region,
                "cust_id" : this.rollFormer,
                "salesman_id" : this.sales,
                "project_type" : this.type,
                "project_category" : this.category,
                "asosiasi" : this.association,
                "partner_id" : this.partner,
                "province" : this.province,
                "city" : this.city,
                "application" : this.appl_search,
                "period_start" : periodStart,
                "period_end" : periodEnd
            }
            this.data = await this.headerController(reqBody)
        },
        async editHeader(event){
            this.$store.dispatch('setOverlay', true)
            this.isRegionDialogEditDisabled = true
            this.isSalesDialogEditDisabled = true
            this.isStartProjectEditDialogDisabled = true
            this.isView = true
            this.dialogTitle = 'Project :'
            var reqBody = {
                "mill_id" : "",
                "project_id" : event.project_id.trim(),
                "region" : "",
                "cust_id" : "",
                "salesman_id" : "",
                "project_type" : "",
                "project_category" : "",
                "asosiasi" : "",
                "partner_id" : "",
                "province" : "",
                "city" : "",
                "period_start" : "",
                "period_end" : ""
            }
            var head = await this.headerController(reqBody)
            
            var det = await this.detailController(reqBody)
            
            // console.log(head, det)
            this.projectId = head[0].project_id
            this.regionDialog = head[0].region
            await this.getSalesman(this.regionDialog)
            this.salesDialog = head[0].salesman_id.trim()
            this.typeDialog = head[0].project_type
            this.projectNameDialog = head[0].project_name
            this.categoryDialog = head[0].project_category
            this.rollFormerDialog = head[0].cust_id.trim()
            this.projectAddressDialog = head[0].project_address
            this.provinceDialog = head[0].project_province
            await this.getCity(this.provinceDialog)
            this.cityDialog = head[0].project_city.trim()
            this.partnerDialog = head[0].partner_id.trim()
            this.projectPhoneDialog = head[0].project_pic_phone
            this.associationDialog = head[0].asosiasi
            this.application = head[0].application ? head[0].application : ''
            this.bmt = head[0].bmt ? head[0].bmt : ''
            this.projectSize = head[0].project_size ? head[0].project_size : ''
            
            // subject format
            this.startDateDialog = head[0].start_date
            this.endDateDialog = head[0].end_date
            var periodStart = '';
            var periodEnd = '';
            periodStart = this.startDateDialog ? this.startDateDialog.substr(0, 10) : ''
            // console.log(new Date(this.endDateDialog))
            // console.log(new Date(this.startDateDialog).toISOString())
            // console.log(this.startDateDialog.substr(0, 10))
            periodEnd = this.endDateDialog ? this.endDateDialog.substr(0, 10) : '1900-01-01'.substr(0, 10)
            this.startDateDialog = periodStart
            this.endDateDialog = periodEnd
            this.detailData = det
            
            this.isView = false
            this.$store.dispatch('setOverlay', false)
        },
        async headerController(reqBody){
            this.$store.dispatch('setOverlay', true)
            const respData = await projectTracking.fetchHeader(``, reqBody, false, false, false)
            if (respData.status === 200) {
                // this.data = respData.data.data
                this.$store.dispatch('setOverlay', false)
                return respData.data.data
            } else {
                this.$store.dispatch('setOverlay', false)
                return []
            }
        },
        async getDetail(event){
            var reqBody = {
                "mill_id" : "",
                "project_id" : this.projectId.trim(),
                "project_num" : ""
            }
            this.detailData = await this.detailController(reqBody)
        },
        async detailController(reqBody){
            this.$store.dispatch('setOverlay', true)
            const respData = await projectTracking.fetchDetail(``, reqBody, false, false, false)
            if (respData.status === 200) {
                this.$store.dispatch('setOverlay', false)
                return respData.data.data
            } else {
                this.$store.dispatch('setOverlay', false)
                return []
            }
        },
        async imagesController(reqBody){
            this.$store.dispatch('setOverlay', true)
            const respData = await projectTracking.fetchImages(``, reqBody, false, false, false)
            if (respData.status === 200) {
                this.$store.dispatch('setOverlay', false)
                return respData.data.data
            } else {
                this.$store.dispatch('setOverlay', false)
                return []
            }
        },
        async saveHeader(event){
            if(this.regionDialog === "" || this.salesDialog === "" || this.typeDialog === "" || this.projectNameDialog === "" || this.categoryDialog === "" 
                || this.rollFormerDialog === "" || this.partnerDialog === "" || this.associationDialog === "" || this.startDateDialog === "" || this.application === ""){
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: 'Please fill all required data',
                    visible: true
                };
            } else {
                this.$store.dispatch('setOverlay', true)
                var periodStart = '';
                var periodEnd = '';
                periodStart = this.startDateDialog ? new Date(this.startDateDialog).toISOString().substr(0, 10) : ''
                periodEnd = this.endDateDialog ? new Date(this.endDateDialog).toISOString().substr(0, 10) : new Date('1900-01-01').toISOString().substr(0, 10)
                var reqBody = {
                        'mill_id': 'SR',
                        "region": this.regionDialog,
                        "salesman_id": this.salesDialog,
                        "project_type": this.typeDialog,
                        "project_name": this.projectNameDialog,
                        "project_category": this.categoryDialog,
                        "cust_id": this.rollFormerDialog,
                        "project_address": this.projectAddressDialog,
                        "project_province": this.provinceDialog,
                        "project_city": this.cityDialog,
                        "partner_id": this.partnerDialog,
                        "project_pic_phone": this.projectPhoneDialog,
                        "asosiasi": this.associationDialog,
                        "status": "O",
                        "start_date": periodStart,
                        "end_date": periodEnd,
                        "project_size": this.projectSize ? this.projectSize : '',
                        "bmt": this.bmt ? this.bmt : '',
                        "application": this.application
                    }
                const respData = await projectTracking.insertData('', reqBody, false, false, false)
                if (respData.status != 200) {
                    console.log(respData)
                    this.$store.dispatch('setOverlay', false)
                    this.snackbar = {
                        color: "warning",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Warning",
                        text: respData.data.data,
                        visible: true
                    };
                    return false
                } else {
                    this.$store.dispatch('setOverlay', false)
                    console.log(respData)
                    // this.projectId = respData.data.data
                    this.projectId = respData.data.data
                    this.isDetailDialogDisabled = false
                    this.disableSaveHeader = true
                    this.snackbar = {
                        color: "success",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Success",
                        text: `Project number ${respData.data.data} had been created`,
                        visible: true
                    };
                }
            }
        },
        async updateHeader(event){
            if(this.regionDialog === "" || this.salesDialog === "" || this.typeDialog === "" || this.projectNameDialog === "" || this.categoryDialog === "" 
                || this.rollFormerDialog === "" || this.partnerDialog === "" || this.associationDialog === "" || this.startDateDialog === "" || this.application === ""){
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: 'Please fill all required data',
                    visible: true
                };
            } else {
                this.$store.dispatch('setOverlay', true)
                var periodStart = '';
                var periodEnd = '';
                periodStart = this.startDateDialog ? new Date(this.startDateDialog).toISOString().substr(0, 10) : ''
                periodEnd = this.endDateDialog ? new Date(this.endDateDialog).toISOString().substr(0, 10) : new Date('1900-01-01').toISOString().substr(0, 10)
                var reqBody = {
                        'mill_id': 'SR',
                        "project_id":this.projectId.trim(),
                        "region": this.regionDialog,
                        "salesman_id": this.salesDialog,
                        "project_type": this.typeDialog,
                        "project_name": this.projectNameDialog,
                        "project_category": this.categoryDialog,
                        "cust_id": this.rollFormerDialog,
                        "project_address": this.projectAddressDialog,
                        "project_province": this.provinceDialog,
                        "project_city": this.cityDialog,
                        "partner_id": this.partnerDialog,
                        "project_pic_phone": this.projectPhoneDialog,
                        "asosiasi": this.associationDialog,
                        "status": "O",
                        "start_date": periodStart,
                        "end_date": periodEnd,
                        "project_size": this.projectSize ? this.projectSize : '',
                        "bmt": this.bmt ? this.bmt : '',
                        "application": this.application
                    }
                const respData = await projectTracking.updateData('', reqBody, false, false, false)
                if (respData.status != 200) {
                    console.log(respData)
                    this.$store.dispatch('setOverlay', false)
                    this.snackbar = {
                        color: "warning",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Warning",
                        text: respData.data.data,
                        visible: true
                    };
                    return false
                } else {
                    this.$store.dispatch('setOverlay', false)
                    console.log(respData)
                    // this.projectId = respData.data.data
                    this.isDetailDialogDisabled = true
                    this.snackbar = {
                        color: "success",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Success",
                        text: `Project number ${respData.data.data} had been updated`,
                        visible: true
                    };
                }
            }
        },
        async deleteHeader(event){
            this.$store.dispatch('setOverlay', true)
            var reqBody = {
                    'mill_id': 'SR',
                    "project_id":event.project_id.trim()
                }
            const respData = await projectTracking.deleteData('', reqBody, false, false, false)
            if (respData.status != 200) {
                console.log(respData)
                this.$store.dispatch('setOverlay', false)
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: respData.data.data,
                    visible: true
                };
                return false
            } else {
                this.$store.dispatch('setOverlay', false)
                console.log(respData)
                // this.projectId = respData.data.data
                this.isDetailDialogDisabled = true
                this.snackbar = {
                    color: "success",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: `Project number ${respData.data.data} had been deleted`,
                    visible: true
                };
                if(this.dialogCreate == true){
                    this.cancel()
                }
            }
        },
        async closeHeader(event){
            this.$store.dispatch('setOverlay', true)
            var periodEnd = '';
            periodEnd = this.endDateDialog ? new Date(this.endDateDialog).toISOString().substr(0, 10) : new Date('1900-01-01').toISOString().substr(0, 10)
            var reqBody = {
                    'mill_id': 'SR',
                    "project_id":this.projectId.trim(),
                    "end_date": periodEnd
                }
            const respData = await projectTracking.closedData('', reqBody, false, false, false)
            if (respData.status != 200) {
                console.log(respData)
                this.$store.dispatch('setOverlay', false)
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: respData.data.data,
                    visible: true
                };
                return false
            } else {
                this.$store.dispatch('setOverlay', false)
                console.log(respData)
                // this.projectId = respData.data.data
                this.isDetailDialogDisabled = true
                this.snackbar = {
                    color: "success",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: `Project number ${respData.data.data} had been closed`,
                    visible: true
                };
            }
        },
        async saveDetail(event){
            // console.log(this.files)
            // console.log(this.files.length)
            
            if(this.surveyDateDialog === "" || this.stageDialog === ""){
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: 'Please fill all required data',
                    visible: true
                };
            } else if(this.isUploadFileRequired && this.files.length == 0){
                console.log('save detail', this.isUploadFileRequired)
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: 'Please fill all required data',
                    visible: true
                }
            } else {
                this.$store.dispatch('setOverlay', true)
                var periodStart = '';
                periodStart = this.startDateDialog ? new Date(this.startDateDialog).toISOString().substr(0, 10) : ''
                let reqBody = new FormData()
                reqBody.append('mill_id', 'SR');
                reqBody.append('project_id', this.projectId);
                reqBody.append('stage_id', this.stageDialog);
                reqBody.append('survey_date', periodStart);
                reqBody.append('remark', this.remarkDialog);
                reqBody.append('file_path', this.filePath);
                var total = 1;
                for( var i = 0; i < this.files.length; i++ ){
                    let files = this.files[i];
                    reqBody.append('file[' + i + ']', files);
                    total = total + i
                }
                var respData = await projectTracking.insertDetail('', reqBody, false, false, false)
                if (respData.status != 200) {
                    this.$store.dispatch('setOverlay', false)
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Warning",
                        text: respData.data.data,
                        visible: true
                    };
                } else {
                    this.$store.dispatch('setOverlay', false)
                    this.snackbar = {
                        color: "success",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Success",
                        text: respData.data.data,
                        visible: true
                    };
                    this.clearDetail()
                    this.getDetail()
                }
            }
        },
        async deleteDetail(event){
            this.$store.dispatch('setOverlay', true)
            var reqBody = {
                    'mill_id': 'SR',
                    "project_id":event.project_id.trim(),
                    "project_num":event.project_num
                }
            const respData = await projectTracking.deleteDetail('', reqBody, false, false, false)
            if (respData.status != 200) {
                console.log(respData)
                this.$store.dispatch('setOverlay', false)
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: respData.data.data,
                    visible: true
                };
                return false
            } else {
                this.$store.dispatch('setOverlay', false)
                this.isDetailDialogDisabled = true
                this.snackbar = {
                    color: "success",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: `Project number ${respData.data.data} had been deleted`,
                    visible: true
                };
                await this.getDetail()
                if(this.detailData.length == 0){
                    this.deleteHeader(event)
                }
            }
        },
        async showImageDialog(event){
            var projecPath = '/sr/uploads/project_tracking/'
            var reqBody = {
                'mill_id': event.mill_id.trim(),
                "project_id":event.project_id.trim(),
                "project_num":event.project_num
            }
            console.log(event)
            
            this.dialogAlbum = true
            // TODO
            var a =  await this.imagesController(reqBody)
            if(a != []){
                this.pictureDatas = a
            } else {

            }
            
        },
        closeDialogAlbum(){
            this.dialogAlbum = false
            this.pictureDatas = []
        },
        async deleteDetailDialog(event){
            // TODO tambah dialog confirm kalau diperlukan
            this.deleteDetail(event)
        },

        handleFileUpload(event){},
        async getPartnerDetail(event){
            if (event == null || event == '') {
                this.partnerDialog = '' 
            } else {
                console.log(event);
                var reqBody = {
                    'mill_id': 'SR',
                    "partner_id":event
                }
                const respData = await projectTracking.getDetailPartner('', reqBody, false, false, false)
                if (respData.status == 200) {
                    console.log(respData.data.data);
                    this.projectPhoneDialog = respData.data.data ? respData.data.data.partner_phone : ''
                }
            }
        },
        async exportExcel(){
            var periodStart = this.period ? new Date(this.period).toISOString().substr(0, 10) : ''
            var periodEnd = this.period_to ? new Date(this.period_to).toISOString().substr(0, 10) : ''
            var reqBody = {
                "region" : this.region,
                "cust_id" : this.rollFormer,
                "salesman_id" : this.sales,
                "project_type" : this.type,
                "project_category" : this.category,
                "asosiasi" : this.association,
                "partner_id" : this.partner,
                "province" : this.province,
                "city" : this.city,
                "application" : this.appl_search,
                "period_start" : periodStart,
                "period_end" : periodEnd
            }

            const respData = await projectTracking.fetchHeader(``, reqBody, false, false, false)
            if (respData.status === 200) {
                return respData.data.data
            }
        },
        startDownload(){
            this.$store.dispatch('setOverlay', true)
        },
        finishDownload(){
            this.$store.dispatch('setOverlay', false)
        }
    },
    watch:{},
    beforeRouteEnter(to, from, next) {
        next(vm => {
            // TODO nanti cek auth dibetulin lagi
            // vm.user.checkAuth()
            console.log(vm.isCreate)
            vm.initView()
        });
    },
    beforeRouteLeave(to, from, next) {
        this.clearFilter();
        this.clear();
        this.clearDetail();
        next()
    },
}

</script>

<style>
.tr_datatable{
  background-color: #F5F7F8 !important;
}
</style>